@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

:root {	
  --gradientColor: linear-gradient(90deg, rgba(251,15,200,1) 0%, rgba(255,125,25, 1) 100%);
  --mainColor: #250B66;
  --secondColor: #FB0FC8;
  --thirdColor: #FF7D19;
}

.main-color{
	color: var(--mainColor);
}

.second-color{
	color: var(--secondColor);
}

.third-color{
	color: var(--thirdColor);
}

body{
	font-family: "Work Sans",sans-serif !important;
    font-size: 14px !important;
    line-height: 1.875em !important;
    letter-spacing: 0;
    color: #323a45 !important;
}

body, h1, h2 {
    font-weight: 400;
}

body a{
	text-decoration:none;
	color: #424769;
}

body svg{
	vertical-align: baseline;
}

body button{
	background-color: transparent;
	color: var(--bs-nav-link-color);
	border: 0px;
}

.app{
	background-color: #f5f4f4;
}

.layout-container, .navbar{
	width: 680px;
	margin: 0 auto;
}

.app-logo {
  height: 33px;
  pointer-events: none;
}

.gap-10{
	height: 10px;
}
.gap-20{
	height: 20px;
}
.gap-30{
	height: 30px;
}

.navbar, .navbar-container{	
	background-color: #FFF;
    border-bottom: 1px solid #e9ebec;
}

.navbar-toggler{
	border-radius: 90px !important;
}

.navbar-toggler-icon{
	width: 0.5em !important;
	height: 1em !important;
}

.main-btn{
	display: block;
	border-radius: 45px;
	background: var(--gradientColor);
	color: #FFF;
	padding: 5px 20px;
	cursor: pointer;
	text-align: center;
	box-shadow: 3px 3px 3px #AAA;
}

.cus-btn{
	display: block;
	padding: 8px 20px;
	border-radius: 90px;
	text-align: center;
    text-decoration: none !important;
	font-size: 14px;
    line-height: 2em;
    letter-spacing: .05em;
    font-weight: 600;
    text-transform: uppercase;
    box-sizing: border-box;
    margin: 0;
	cursor: pointer;
}

.cus-btn.whatsapp-btn{
	background: #128C7E;
	color: #FFF;
    border: 1px solid #128C7E;
}

.cus-btn.white-btn{
	background: #FFF;
	color: #323a45;
    /*border: 1px solid #1D2127;*/
}

.cus-btn.black-btn{
	background: #1D2127;
	color: white;
    /*border: 1px solid #1D2127;*/
}

.cus-btn.transparent-btn{
	background: transparent;
	color: #323a45;
	border: 1px solid #323a45;
}

.cus-btn.main-btn{
    background: var(--gradientColor);
	color: #fff;
    border: 0;
}

.cus-btn svg{
	font-size: 120%;
	vertical-align: middle;
}

.login-container{
	border: 1px solid #EFEFEF;
	border-radius: 12px;
	background-color: #FFF;
	padding: 2em;
}

.btn-login button{
	border: 1px solid #EFEFEF;
	border-radius: 12px;
	background-color: #FFF;
	padding: 1em;
	width: 100%;
	font-size: 14px;
    font-weight: 600;
    color: #323a45;
}

.login-icon{
	width: 48px;
	height: 48px;
}

.icon{
	width: 16px;
	height: 16px;
}

#dropdown-profile{
    background: transparent;
    border: 0;
	color: #1d2127;
    background: var(--gradientColor);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0;
    word-wrap: break-word;
    overflow: hidden;
}

#page-banner{
	width: 100%;
	border-radius: 20px;
	object-fit: cover;
	aspect-ratio: 5.48;
}

.profile-image-container{
	position: relative;
}

ul.nav-tab-button{
	list-style: none;
	padding: 0;
	overflow: hidden;
	margin: 1rem 0 1rem 0;
	display: flex;
	justify-content: center;
}

/*ul.nav-tab-button li:not(:last-child) {
	margin-right: 6px;
}*/

ul.nav-tab-button li {
	margin: 0 auto;
}

ul.nav-tab-button li a{
	display: block;
    padding: 8px 14px;
}

.cus-container{
	box-sizing: border-box;
    padding: 24px 20px 24px;
    border-radius: 25px;
	margin-bottom: 1rem;
}

.cus-container h4{
	text-align: center;
}

.cus-container.white-bg{
    background-color: #fff;
}

.bloom-content{
	position: relative;
	padding: 0 10px 16px;
	top: -50px;
}

.profile-image{
	display: inline-block;
	height: 100px;
    width: 100px;
	border-radius: 20px;
    border: 4px solid #F5F4F4;
    object-fit: cover;
}

.profile-image-container h2{
	font-size: 24px;
	font-weight: 500;
	display: inline-block;
}

.profile-image-container .ic-fav{
	vertical-align: top;
}

.profile-title{
	margin-top: 5px;
	font-weight: 500;
    font-size: 22px;
    text-transform: capitalize;
}

.bloom-bio{
	margin-top: 1rem;
    text-transform: capitalize;
}

.feature-images-container{
	list-style-type: none;
	/*overflow-x: scroll;*/
	padding: 0;
}

.feature-images-container li{
    aspect-ratio: 1 / 1;
	margin-right: 12px;
    border-radius: 20px;
	background-image: -webkit-gradient(linear, left bottom, right top, color-stop(0.33, rgb(251, 15, 200)), color-stop(0.67, rgb(255, 125, 25)));
    /*background-image: -moz-linear-gradient(-45deg, rgb(251, 15, 200) 33%, rgb(255, 125, 25) 67%);*/
  	padding: 4px;
	margin: 5px;
	display: inline-block;
	width: 30%;
	text-align: center;
}

.feature-images-container img{
    width: 100%;
    aspect-ratio: 1 / 1;
	object-fit: cover;
	border-radius: 17px;
}

.bloom-links-list,
.bookmark-list{
	padding: 0;
}

.bookmark-list svg{
	color: #dc3545;
}

.bookmark-list li{
	width: calc(100% - 35px);
	display: inline-block;
	list-style-type: none;
	border: 1px solid #e9ebec;
	border-radius: 15px;
	background: #1D2127;
	color: #FFF;
	padding: 12px 20px;
    margin-right: 12px;
	margin-bottom: 12px;
	cursor: pointer;
}

.bloom-links-list li{
	list-style-type: none;
	border-radius: 45px;
	background: #1D2127;
	color: #FFF;
	padding: 5px 20px;
    margin-bottom: 12px;
	cursor: pointer;
	text-align: center;
	box-shadow: 3px 3px 3px #AAA;
}

.bloom-links-list li img,
.bookmark-list li img{
	border-radius: 5px;
	height: 30px;
	margin-right: 8px;
	display: inline-block;
}

.bloom-links-list li h6,
.bookmark-list li h6{
	width: calc(100% - 38px);
	margin-bottom: 0;
	display: inline-block;
	vertical-align: middle;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	font-size: 14px;
}

.claim-stamp-content{
	text-align: center;
}

.claim-stamp-content h2{
	padding-top: 0.5em;
	padding-bottom: 1em;
}

.dl-app-container .img-dl-app{
	height: 60px;
	display: inline-block;
	margin: 0 auto;
	padding-bottom: 1em;
	padding-left: 1em;
	padding-right: 1em;
}

@media screen and (max-width: 680px){
	.navbar{
		width: 100%;
	}
	.layout-container{
		width: 90%;
	}
	.dl-app-container .img-dl-app{
		display: block;
	}
}
